import type { AxiosResponse } from 'axios';

import { getFormData } from 'services/api/utils/getFormData';
import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { CoverImage } from 'types/brand.types';
import {
  NewsletterPublishSettings,
  NewsletterPublishSettingsParams,
} from 'types/newsletter-publish.types';
import {
  NewsletterTemplate,
  NewsletterTemplateCreateParams,
  NewsletterTemplateSearchParams,
  NewsletterTemplateUpdateParams,
  TemplateSubscription,
  TemplateSubscriptionParams,
} from 'types/template.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export default {
  fetchTemplates(this: RestApiService, userId: User['id'], params: NewsletterTemplateSearchParams) {
    const cleanParams = removeEmptyValuesFromParams(params);

    return this.api.get<ApiResponse<NewsletterTemplate[]>>(`/users/${userId}/newslettertemplates`, {
      params: cleanParams,
    });
  },
  fetchTemplate(this: RestApiService, templateId: NewsletterTemplate['id']) {
    return this.api.get<NewsletterTemplate>(`/newslettertemplates/${templateId}`);
  },

  createTemplate(this: RestApiService, params: NewsletterTemplateCreateParams) {
    return this.api.post<NewsletterTemplateCreateParams, AxiosResponse<NewsletterTemplate>>(
      `/newslettertemplates`,
      params,
    );
  },

  updateTemplate(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    params: NewsletterTemplateUpdateParams,
  ) {
    return this.api.put<NewsletterTemplateUpdateParams, AxiosResponse<NewsletterTemplate>>(
      `/newslettertemplates/${templateId}`,
      params,
    );
  },

  deleteTemplate(this: RestApiService, templateId: NewsletterTemplate['id']) {
    return this.api.delete(`/newslettertemplates/${templateId}`);
  },

  deleteTemplates(this: RestApiService, templateIds: NewsletterTemplate['id'][]) {
    return this.api.delete<null>('/newslettertemplates', { data: templateIds });
  },

  fetchTemplatePublishSettings(this: RestApiService, templateId: NewsletterTemplate['id']) {
    return this.api.get<NewsletterPublishSettings>(
      `/newslettertemplates/${templateId}/publishsettings`,
    );
  },

  updateTemplatePublishSettings(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    params: NewsletterPublishSettingsParams,
  ) {
    return this.api.put<NewsletterPublishSettingsParams, AxiosResponse<NewsletterPublishSettings>>(
      `/newslettertemplates/${templateId}/publishsettings`,
      params,
    );
  },

  fetchTemplateSubscription(
    this: RestApiService,
    userId: User['id'],
    templateId: NewsletterTemplate['id'],
  ) {
    return this.api.get<TemplateSubscription>(
      `users/${userId}/kiosk/recurringtemplates/${templateId}/subscription`,
    );
  },

  updateTemplateSubscription(
    this: RestApiService,
    userId: User['id'],
    templateId: NewsletterTemplate['id'],
    params: TemplateSubscriptionParams,
  ) {
    return this.api.put<TemplateSubscriptionParams, AxiosResponse<TemplateSubscription>>(
      `users/${userId}/kiosk/recurringtemplates/${templateId}/subscription`,
      params,
    );
  },

  detachTemplate(this: RestApiService, templateId: NewsletterTemplate['id'], userId: User['id']) {
    return this.api.delete<null>(`/newslettertemplates/${templateId}/collaborators/${userId}`);
  },

  updateTemplateCoverImage(
    this: RestApiService,
    templateId: NewsletterTemplate['id'],
    coverImage: Blob,
  ) {
    return this.api.put<FormData, AxiosResponse<CoverImage>>(
      `/newslettertemplates/${templateId}/cover`,
      getFormData({ coverImage }),
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  },

  deleteTemplateCoverImage(this: RestApiService, templateId: NewsletterTemplate['id']) {
    return this.api.delete<{ coverImage: string }>(`/newslettertemplates/${templateId}/cover`);
  },
  restoreTemplateCoverImage(this: RestApiService, templateId: NewsletterTemplate['id']) {
    return this.api.put<CoverImage>(`/newslettertemplates/${templateId}/cover/reset`);
  },
};
