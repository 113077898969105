import { DateRangeType } from 'utils/date.utils';

export enum SearchModes {
  ONE_OF = 'one_of',
  ALL_OF = 'all_of',
  EXACT = 'exact',
}

export enum SearchType {
  Manual = 'manual',
  Assisted = 'assisted',
}

export interface UrlParams {
  isManual?: boolean;
  hasSearched: boolean;
  searchText?: string;
  searchMode?: SearchModes;
  page?: number;
}

export interface SearchHistorySearchParams {
  count?: number;
  offset?: number;
}

export type SearchHistory = PreviousSearch[];

export type SearchHistoryParams = {
  searchDate: string;
  queryObject: QueryObject;
};

export type PreviousSearch = {
  id: number;
  queryObject: QueryObject;
  searchFields: {
    isManual?: string;
    query?: string;
    searchMode?: SearchModes;
  };
  searchDate: string;
};

/**
 * @note This should normally mimic the params on news object but it has
 * different casing and most of the params are now proper arrays instead of comma string lists
 */
export type QueryObject = {
  searchText?: string;
  searchMode?: SearchModes;
  isManual?: boolean;
  dateRangeType?: DateRangeType;
  collapseDuplicates?: boolean;
  exactQuery?: boolean;
  mediaTypes?: string[];
  favouriteNewsbrands?: boolean;
  languages?: string[];
  keyword?: string[];
  title?: string;
  intro?: string;
  author?: string;
  publisher?: string[];
  country?: string[];
  pagenr?: string[];
  wordcountmin?: string;
  wordcountmax?: string;
  tagid?: string[];
  topicIds?: string[];
  start?: string;
  end?: string;
  sourceid?: string[];
  subsourceid?: string[];
  edition?: string[];
  sourcegroupid?: string[];
  order?: string;
};
