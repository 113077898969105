import type { AxiosResponse } from 'axios';
import qs from 'query-string';

import { DateRangeType } from 'utils/date.utils';

import { getFormData } from 'services/api/utils/getFormData';
import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { CoverImage } from 'types/brand.types';
import { NewsObject } from 'types/newsobject.types';
import {
  CompactKioskNewsroom,
  CompactNewsroom,
  CreateNewsroomDocumentParams,
  CreatePressContactParams,
  KioskNewsroom,
  KioskNewsroomDocumentsSearchParams,
  KioskNewsroomEventsSearchParams,
  KioskNewsroomParams,
  KioskNewsroomSearchParams,
  Newsroom,
  NewsroomCreateParams,
  NewsroomDocument,
  NewsroomDocumentsSearchParams,
  NewsroomEvent,
  NewsroomEventCompact,
  NewsroomEventParams,
  NewsroomEventSearchParams,
  NewsroomLanguages,
  NewsroomNotification,
  NewsroomNotificationInstance,
  NewsroomSearchParams,
  NewsroomSubSource,
  NewsroomUpdateParams,
  PressContact,
  PressContactImage,
  PressContactsSearchParams,
  PressContactUpdateParams,
  PressCoveragesSearchParams,
  UpdateNewsroomDocumentParams,
} from 'types/newsroom.types';
import {
  NewsroomInsightsFollowers,
  NewsroomInsightsGlobals,
  NewsroomInsightsUniqueVisitors,
  NewsroomPageActivity,
} from 'types/newsroom-insights.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchUserNewsrooms(
  this: RestApiService,
  userId: User['id'],
  dirtyParams: NewsroomSearchParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<CompactNewsroom[]>>(`/users/${userId}/newsrooms`, { params });
}

export function fetchNewsroomById(this: RestApiService, newsroomId: Newsroom['id']) {
  return this.api.get<Newsroom>(`/newsrooms/${newsroomId}`);
}

export function createNewsroom(this: RestApiService, params: NewsroomCreateParams) {
  return this.api.post<NewsroomCreateParams, AxiosResponse<Newsroom>>(`/newsrooms`, params);
}

export function fetchNewsroomSubSourceName(
  this: RestApiService,
  subSourceName: Newsroom['subSourceName'],
) {
  return this.api.get<NewsroomSubSource>(`/newsrooms/subsources`, {
    params: { name: subSourceName },
  });
}

export function publishNewsroom(this: RestApiService, newsroomId: Newsroom['id']) {
  return this.api.put<Newsroom>(`/newsrooms/${newsroomId}/publish`);
}
export function unpublishNewsroom(this: RestApiService, newsroomId: Newsroom['id']) {
  return this.api.put<Newsroom>(`/newsrooms/${newsroomId}/unpublish`);
}
export function updateNewsroom(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: NewsroomUpdateParams,
) {
  return this.api.put<NewsroomUpdateParams, AxiosResponse<Newsroom>>(
    `/newsrooms/${newsroomId}`,
    params,
  );
}

export function deleteNewsroom(this: RestApiService, newsroomId: Newsroom['id']) {
  return this.api.delete(`/newsrooms/${newsroomId}`);
}

export function detachNewsroom(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  userId: User['id'],
) {
  return this.api.delete(`/newsrooms/${newsroomId}/collaborators/${userId}`);
}

export function restoreNewsroomCoverImage(this: RestApiService, newsroomId: Newsroom['id']) {
  return this.api.put<CoverImage>(`/newsrooms/${newsroomId}/cover/reset`);
}

// NEWSROOM DOCUMENTS
export function fetchNewsroomDocuments(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: NewsroomDocumentsSearchParams,
) {
  return this.api.get<ApiResponse<NewsroomDocument[]>>(`/newsrooms/${newsroomId}/documents`, {
    params: { ...params, parentId: params.parentId || null },
    paramsSerializer: p => qs.stringify(p),
  });
}

export function createNewsroomDocument(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: CreateNewsroomDocumentParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);
  return this.api.post<NewsroomDocument>(`/newsrooms/${newsroomId}/documents`, params);
}

export function updateNewsroomDocument(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  documentId: NewsroomDocument['id'],
  dirtyParams: UpdateNewsroomDocumentParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);
  return this.api.patch<NewsroomDocument>(
    `/newsrooms/${newsroomId}/documents/${documentId}`,
    params,
  );
}

export function deleteNewsroomDocument(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  documentId: NewsroomDocument['id'],
) {
  return this.api.delete(`/newsrooms/${newsroomId}/documents/${documentId}`);
}

// PRESS COVERAGES
export function fetchNewsroomPressCoverages(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: PressCoveragesSearchParams,
  headers?: Record<string, string>,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<NewsObject[]>>(`/newsrooms/${newsroomId}/coverages`, {
    params,
    headers,
  });
}

export function createPressCoverages(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: {
    language: string;
    newsObjectUuids: string[];
  },
) {
  return this.api.post<string[], AxiosResponse<NewsObject>>(`/newsrooms/${newsroomId}/coverages`, {
    ...params,
  });
}

export function deletePressCoverages(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: string[],
) {
  return this.api.delete(`/newsrooms/${newsroomId}/coverages`, { data: params });
}

// PRESS CONTACTS
export function fetchNewsroomPressContacts(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: PressContactsSearchParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<PressContact[]>>(`/newsrooms/${newsroomId}/presscontacts`, {
    params,
  });
}

export function createPressContact(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: CreatePressContactParams,
) {
  return this.api.post<CreatePressContactParams, AxiosResponse<PressContact>>(
    `/newsrooms/${newsroomId}/presscontacts`,
    params,
  );
}

export function createPressContactProfilePicture(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressContactId: PressContact['id'],
  profilePicture: Blob,
) {
  return this.api.post<FormData, AxiosResponse<PressContactImage>>(
    `/newsrooms/${newsroomId}/presscontacts/${pressContactId}/picture`,
    getFormData({ profilePicture }),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}

export function deletePressContact(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressContactId: PressContact['id'],
) {
  return this.api.delete(`/newsrooms/${newsroomId}/presscontacts/${pressContactId}`);
}

export function updatePressContact(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  pressContactId: PressContact['id'],
  params: PressContactUpdateParams,
) {
  return this.api.put<PressContactUpdateParams, AxiosResponse<PressContact>>(
    `/newsrooms/${newsroomId}/presscontacts/${pressContactId}`,
    params,
  );
}

// Kiosk endpoints
export function fetchKioskNewsrooms(
  this: RestApiService,
  userId: User['id'],
  dirtyParams: KioskNewsroomSearchParams,
) {
  return this.api.get<ApiResponse<CompactKioskNewsroom[]>>(`/users/${userId}/kiosk/newsrooms`, {
    params: removeEmptyValuesFromParams(dirtyParams),
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma' }),
  });
}

export function fetchKioskNewsroomById(
  this: RestApiService,
  { userId, newsroomId }: KioskNewsroomParams,
) {
  return this.api.get<KioskNewsroom>(`/users/${userId}/kiosk/newsrooms/${newsroomId}`);
}

export function fetchKioskNewsroomDocuments(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  params: Omit<KioskNewsroomDocumentsSearchParams, 'offset'>,
) {
  return this.api.get<ApiResponse<NewsroomDocument[]>>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/documents`,
    {
      params: { ...params, parentId: params.parentId || null },
      paramsSerializer: p => qs.stringify(p),
    },
  );
}

export function fetchKioskNewsroomEvents(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  params: KioskNewsroomEventsSearchParams,
) {
  return this.api.get<ApiResponse<NewsroomEvent[]>>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/events`,
    { params },
  );
}
export function fetchKioskNewsroomEventById(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  eventId: NewsroomEvent['id'],
) {
  return this.api.get<NewsroomEvent>(
    `/users/${userId}/kiosk/newsrooms/${newsroomId}/events/${eventId}`,
  );
}

// Newsroom - Events
export function createNewsroomEvent(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: NewsroomEventParams,
) {
  return this.api.post<NewsroomEventParams, AxiosResponse<NewsroomEventCompact>>(
    `/newsrooms/${newsroomId}/events`,
    removeEmptyValuesFromParams(dirtyParams),
  );
}

export function fetchNewsroomEvents(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  dirtyParams: NewsroomEventSearchParams,
) {
  return this.api.get<ApiResponse<NewsroomEventCompact[]>>(`/newsrooms/${newsroomId}/events`, {
    params: removeEmptyValuesFromParams(dirtyParams),
  });
}
export function fetchNewsroomEventById(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  eventId: NewsroomEventCompact['id'],
) {
  return this.api.get<NewsroomEvent>(`/newsrooms/${newsroomId}/events/${eventId}`);
}

export function deleteNewsroomEvent(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  eventId: NewsroomEventCompact['id'],
) {
  return this.api.delete(`/newsrooms/${newsroomId}/events/${eventId}`);
}

export function updateNewsroomEvent(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  eventId: NewsroomEvent['id'],
  params: NewsroomEventParams,
) {
  return this.api.put<PressContactUpdateParams, AxiosResponse<NewsroomEvent>>(
    `/newsrooms/${newsroomId}/events/${eventId}`,
    params,
  );
}
export function followNewsroom(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  userId: User['id'],
) {
  return this.api.put(`/users/${userId}/newsrooms/${newsroomId}/follow`);
}

export function unFollowNewsroom(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  userId: User['id'],
) {
  return this.api.put(`/users/${userId}/newsrooms/${newsroomId}/unfollow`);
}

export function fetchNewsroomInsightsGlobals(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: { dateRangeType: DateRangeType },
) {
  return this.api.get<NewsroomInsightsGlobals>(`/newsrooms/${newsroomId}/insights/globals`, {
    params,
  });
}
export function fetchNewsroomUniqueVisitors(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: { dateRangeType: DateRangeType },
) {
  return this.api.get<NewsroomInsightsUniqueVisitors>(
    `/newsrooms/${newsroomId}/insights/visitors`,
    {
      params,
    },
  );
}
export function fetchNewsroomPageActivity(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: { dateRangeType: DateRangeType },
) {
  return this.api.get<NewsroomPageActivity>(`/newsrooms/${newsroomId}/insights/pageactivity`, {
    params,
  });
}

export function fetchNewsroomFollowers(
  this: RestApiService,
  newsroomId: Newsroom['id'],
  params: { dateRangeType: DateRangeType },
) {
  return this.api.get<NewsroomInsightsFollowers>(`/newsrooms/${newsroomId}/insights/followers`, {
    params,
  });
}

// Newsroom notifications
export function fetchNewsroomNotification(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
) {
  return this.api.get<NewsroomNotification>(
    `/users/${userId}/newsrooms/${newsroomId}/notifications`,
  );
}

export function createNewsroomNotification(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  params: { languages: NewsroomLanguages[] },
) {
  return this.api.post(`/users/${userId}/newsrooms/${newsroomId}/notifications`, {
    ...params,
  });
}

export function updateNewsroomNotification(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
  params: { id: number; languages: NewsroomLanguages[] },
) {
  return this.api.put(`/users/${userId}/newsrooms/${newsroomId}/notifications`, {
    ...params,
  });
}

export function deleteNewsroomNotification(
  this: RestApiService,
  userId: User['id'],
  newsroomId: Newsroom['id'],
) {
  return this.api.delete(`/users/${userId}/newsrooms/${newsroomId}/notifications`);
}

export function fetchAllNewsroomNotifications(this: RestApiService, userId: User['id']) {
  return this.api.get<NewsroomNotificationInstance[]>(`/users/${userId}/newsrooms/notifications`);
}
