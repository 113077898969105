import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  sx?: SxProps<Theme>;
}

const NewChip = ({ sx }: Props) => {
  return (
    <Box
      component="span"
      display="flex"
      height="16px"
      alignItems="center"
      px={0.5}
      borderRadius={8}
      bgcolor="global01.600"
      sx={sx}
      color="global01.400"
    >
      <Typography variant="label01" textTransform="uppercase" fontWeight={600} margin="none">
        <FormattedMessage id="common.new" />
      </Typography>
    </Box>
  );
};

export default NewChip;
