import { Components, Theme } from '@mui/material';

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 'initial',
      marginRight: theme.spacing(1),
    }),
  },
};
