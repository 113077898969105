import { createTheme as createMuiTheme, ThemeOptions } from '@mui/material/styles';

import { baseThemeOptions } from './base';
import * as components from './overrides';

/**
 *  Theme
 */
const createTheme = () =>
  createMuiTheme(baseThemeOptions, {
    mixins: {
      appBar: {
        height: '88px',
        mobileHeight: '56px',
      },
      dashboard: {
        subHeader: {
          height: '72px',
        },
      },
      maintenanceMessages: {
        height: '48px',
      },
      sideBar: {
        width: '290px',
        tabletWidth: '220px',
      },
    },
    components,
  } as ThemeOptions);

export default createTheme;
