import { MediaFile } from 'types/media.types';

export enum EventStatusTypes {
  UPDATED = 'UPDATED',
  NEW = 'NEW',
  DEFAULT = 'DEFAULT',
  OTHER = 'OTHER',
}

export enum CoverageTypes {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum EventRegions {
  BE = 'BE',
  INTERNATIONAL = 'INTERNATIONAL',
}

export type AgendaEventParams = {
  count?: number;
  end?: string;
  offset?: number;
  rubricIds?: number[];
  search?: string;
  start?: string;
  coverageTypes?: CoverageTypes[];
  regions?: EventRegions[];
  status?: EventStatusTypes[];
  showPastOnly?: boolean;
  order?: string;
};

export enum DurationType {
  ALL_DAY = 'ALL_DAY',
  END_OF_DAY = 'END_OF_DAY',
  CUSTOM = 'CUSTOM',
}

export type Accreditation = {
  info: string;
  deadline: string;
};

export type AgendaEvent = {
  id: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  status?: EventStatusTypes;
  accreditation?: Accreditation;
  coverages: {
    hasTextCoverage: boolean;
    hasPictureCoverage: boolean;
    hasVideoCoverage: boolean;
  };
  durationType?: DurationType;
  title: string;
  description: string;
  organizer: string;
  location?: string;
  address: Address;
  attachments?: MediaFile[];
  rubrics?: Rubric[];
  contacts?: AgendaContact[];
  updateDate?: string;
  createDate?: string;
};

export type Address = {
  street?: string;
  number?: string;
  zip: string;
  city: string;
  country: string;
  geometry?: {
    lat: number;
    lng: number;
  };
};

export type Rubric = {
  id: string;
  name: string;
};

export type AgendaContact = {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumbers: string[];
  email: string;
  websiteUrl: string;
};

export type FilterAgendaEvents = {
  region: EventRegions[];
  status: EventStatusTypes[];
  coverageType: CoverageTypes[];
};

export type OrderCoverageParams = {
  eventId: string;
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  phoneNumber: string;
  coverageTypes: CoverageTypes[];
};
