import {
  MultiLanguageContent,
  Newsroom,
  NewsroomLanguagePriority,
  NewsroomLanguages,
  NewsroomUpdateParams,
} from 'types/newsroom.types';
import { UserLanguages } from 'types/user.types';

import { collaborationToParams } from './collaboration.utils';

export const mapUserToNewsroomlanguage = {
  [UserLanguages.EN]: NewsroomLanguages.En,
  [UserLanguages.NL]: NewsroomLanguages.Nl,
  [UserLanguages.FR]: NewsroomLanguages.Fr,
  [UserLanguages.DE]: NewsroomLanguages.En,
};

export const mapNewsroomToUserlanguage = {
  [NewsroomLanguages.En]: UserLanguages.EN,
  [NewsroomLanguages.Nl]: UserLanguages.NL,
  [NewsroomLanguages.Fr]: UserLanguages.FR,
};

export const getPrioritizedLanguages = (languages: NewsroomLanguages[]): NewsroomLanguages[] =>
  languages.sort((a, b) => NewsroomLanguagePriority[a] - NewsroomLanguagePriority[b]);

export const getContentLanguages = (
  content?: MultiLanguageContent<unknown>,
  firstLanguage?: NewsroomLanguages,
): NewsroomLanguages[] => {
  if (!content) return [];

  return Object.keys(content).sort((a, b) => {
    // If a first language is provided, it should be the first in the list
    if (a === firstLanguage) return -1;
    if (b === firstLanguage) return 1;

    return (
      NewsroomLanguagePriority[a as NewsroomLanguages] -
      NewsroomLanguagePriority[b as NewsroomLanguages]
    );
  }) as NewsroomLanguages[];
};

export const newsroomToParams = (
  newsroom: Newsroom,
  params: Partial<NewsroomUpdateParams>,
): NewsroomUpdateParams => ({
  id: newsroom.id,
  subSourceName: newsroom.subSourceName,
  status: newsroom.status,
  brandId: newsroom.brand.id,
  topicIds: newsroom.topics.map(({ id }) => id),
  content: newsroom.content,
  ...params,
  coverImage: params?.coverImage ?? newsroom.coverImage,
  noCoverImage: params?.noCoverImage ?? newsroom.noCoverImage,
  collaboration: {
    ...collaborationToParams(newsroom.collaboration),
    ...params.collaboration,
  },
  termsAcceptedDate: newsroom.termsAcceptedDate,
});

export const urlRegex =
  /^(https?:\/\/)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}((\/[\w \.-]*){0,2}(\/[\w \.-@]*)*)\/?$/;
