import type { AxiosResponse } from 'axios';

import { getFormData } from 'services/api/utils/getFormData';
import { removeEmptyValuesFromParams } from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { CoverImage } from 'types/brand.types';
import {
  DuplicateNewsletterParams,
  Newsletter,
  NewsletterCompact,
  NewsletterCreateParams,
  NewsletterDraftParams,
  NewsletterDraftStatusResponse,
  NewsletterFieldsParams,
  NewsletterGenerateSummaryParams,
  NewsletterGenerateSummaryResponse,
  NewsletterNewsObjectsSearchParams,
  NewsletterOutlineItem,
  NewsletterSearchParams,
  NewsletterUpdateParams,
} from 'types/newsletter.types';
import {
  NewsletterPublishSettings,
  NewsletterPublishSettingsParams,
} from 'types/newsletter-publish.types';
import { NewsObjectCompact } from 'types/newsobject.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchNewsletterById(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.get<Newsletter>(`/newsletters/${newsletterId}`);
}

export function fetchNewsletterDraftStatus(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.get<NewsletterDraftStatusResponse>(`/newsletters/${newsletterId}/draft`);
}

export function fetchNewsletterOutline(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.get<NewsletterOutlineItem[]>(`/newsletters/${newsletterId}/outline`);
}

export function createNewsletter(this: RestApiService, params: NewsletterCreateParams) {
  return this.api.post<NewsletterCreateParams, AxiosResponse<Newsletter>>(`/newsletters`, params);
}

export function updateNewsletter(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: NewsletterUpdateParams,
) {
  return this.api.put<NewsletterUpdateParams, AxiosResponse<Newsletter>>(
    `/newsletters/${newsletterId}`,
    params,
  );
}

export function updateNewsletterFields(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: NewsletterFieldsParams,
) {
  return this.api.patch<NewsletterFieldsParams, null>(`/newsletters/${newsletterId}`, params);
}

export function fetchUserNewsletters(
  this: RestApiService,
  userId: User['id'],
  dirtyParams: NewsletterSearchParams,
) {
  const params = removeEmptyValuesFromParams(dirtyParams);

  return this.api.get<ApiResponse<Newsletter[]>>(`/users/${userId}/newsletters`, { params });
}

export function fetchNewsletterPublishSettings(
  this: RestApiService,
  newsletterId: Newsletter['id'],
) {
  return this.api.get<NewsletterPublishSettings>(`/newsletters/${newsletterId}/publishsettings`);
}

export function updateNewsletterPublishSettings(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: NewsletterPublishSettingsParams,
) {
  return this.api.put<NewsletterPublishSettingsParams, AxiosResponse<NewsletterPublishSettings>>(
    `/newsletters/${newsletterId}/publishsettings`,
    params,
  );
}

export function duplicateNewsletter(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: DuplicateNewsletterParams,
) {
  return this.api.post<DuplicateNewsletterParams, AxiosResponse<Newsletter>>(
    `/newsletters/${newsletterId}/duplicate`,
    params,
  );
}

export function deleteNewsletter(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.delete<null>(`/newsletters/${newsletterId}`);
}

export function detachNewsletter(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  userId: User['id'],
) {
  return this.api.delete<null>(`/newsletters/${newsletterId}/collaborators/${userId}`);
}

export function fetchUserKioskNewsletters(
  this: RestApiService,
  userId: User['id'],
  params: NewsletterSearchParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<NewsletterCompact[]>>(`/users/${userId}/kiosk/newsletters`, {
    params: cleanParams,
  });
}

export function fetchNewsletterNewsObjects(
  this: RestApiService,
  userId: User['id'],
  newsletterId: Newsletter['id'],
  params: NewsletterNewsObjectsSearchParams,
) {
  const cleanParams = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<NewsObjectCompact[]>>(
    `/users/${userId}/kiosk/newsletters/${newsletterId}/newsobjects`,
    { params: cleanParams },
  );
}

export function createNewsletterDraft(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.post<Newsletter>(`/newsletters/${newsletterId}/draft`);
}

export function deleteNewsletterDraft(this: RestApiService, draftId: Newsletter['id']) {
  return this.api.delete(`/newsletters/${draftId}/draft`);
}

export function updateNewsletterDraft(
  this: RestApiService,
  draftId: Newsletter['id'],
  params: NewsletterDraftParams,
) {
  return this.api.put<Newsletter>(`/newsletters/${draftId}/draft`, params);
}

export function updateNewsletterCoverImage(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  coverImage: Blob,
) {
  return this.api.put<FormData, AxiosResponse<CoverImage>>(
    `/newsletters/${newsletterId}/cover`,
    getFormData({ coverImage }),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}
export function deleteNewsletterCoverImage(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.delete<{ coverImage: string }>(`/newsletters/${newsletterId}/cover`);
}
export function restoreNewsletterCoverImage(this: RestApiService, newsletterId: Newsletter['id']) {
  return this.api.put<CoverImage>(`/newsletters/${newsletterId}/cover/reset`);
}

export function generateNewsletterSummary(
  this: RestApiService,
  newsletterId: Newsletter['id'],
  params: NewsletterGenerateSummaryParams,
) {
  return this.api.post<NewsletterGenerateSummaryResponse>(
    `/newsletters/${newsletterId}/summarize`,
    params,
  );
}
