import { Components, Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paperWidthSm: { maxWidth: 640 },
    root: ({ theme }) => ({
      [theme.breakpoints.down('md')]: {
        zIndex: 9999,
      },
    }),
  },
};
