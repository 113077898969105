import type { AxiosResponse } from 'axios';

import { getFormData } from 'services/api/utils/getFormData';
import {
  belgaContextHeaders,
  removeEmptyValuesFromParams,
} from 'services/api/utils/remove-empty-values';
import { ApiResponse } from 'types/api.types';
import { CoverImage } from 'types/brand.types';
import {
  Feed,
  FeedCreateParams,
  FeedNotificationSettings,
  FeedSearchParams,
  FeedUpdateParams,
  KioskFeedNewsObjectsParams,
} from 'types/feed.types';
import { FeedPublishSettings, FeedPublishSettingsParams } from 'types/feed-publish.types';
import { NewsObject, NewsObjectContext } from 'types/newsobject.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchUserFeeds(this: RestApiService, userId: User['id'], params: FeedSearchParams) {
  const _params = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<Feed[]>>(`/users/${userId}/feeds`, {
    params: _params,
  });
}

export function fetchUserKioskFeeds(
  this: RestApiService,
  userId: User['id'],
  params: FeedSearchParams,
) {
  const _params = removeEmptyValuesFromParams(params);

  return this.api.get<ApiResponse<Feed[]>>(`/users/${userId}/kiosk/feeds`, {
    params: _params,
  });
}

export function fetchFeedById(this: RestApiService, feedId: Feed['id']) {
  return this.api.get<Feed>(`/feeds/${feedId}`);
}

export function updateFeed(this: RestApiService, feedId: Feed['id'], params: FeedUpdateParams) {
  return this.api.put<FeedUpdateParams, AxiosResponse<Feed>>(`/feeds/${feedId}`, params);
}

export function deleteFeed(this: RestApiService, feedId: Feed['id']) {
  return this.api.delete(`/feeds/${feedId}`);
}

export function createFeed(this: RestApiService, params: FeedCreateParams) {
  return this.api.post<FeedCreateParams, AxiosResponse<Feed>>(`/feeds`, params);
}

export function fetchFeedPublishSettings(this: RestApiService, feedId: Feed['id']) {
  return this.api.get<FeedPublishSettings>(`/feeds/${feedId}/publishsettings`);
}

export function updateFeedPublishSettings(
  this: RestApiService,
  feedId: Feed['id'],
  params: FeedPublishSettingsParams,
) {
  return this.api.put<FeedPublishSettingsParams, AxiosResponse<FeedPublishSettings>>(
    `/feeds/${feedId}/publishsettings`,
    params,
  );
}

export function fetchFeedNotificationSettings(
  this: RestApiService,
  userId: User['id'],
  feedId: Feed['id'],
) {
  return this.api.get<FeedNotificationSettings>(
    `/users/${userId}/feeds/${feedId}/notificationsettings`,
  );
}

export function updateFeedNotificationSettings(
  this: RestApiService,
  userId: User['id'],
  feedId: Feed['id'],
  params: FeedNotificationSettings,
) {
  return this.api.put<FeedNotificationSettings, AxiosResponse<FeedNotificationSettings>>(
    `/users/${userId}/feeds/${feedId}/notificationsettings`,
    params,
  );
}

export function detachFeed(this: RestApiService, feedId: Feed['id'], userId: User['id']) {
  return this.api.delete<null>(`/feeds/${feedId}/collaborators/${userId}`);
}

export function fetchKioskFeedNewsObjects(
  this: RestApiService,
  feedId: Feed['id'],
  params?: KioskFeedNewsObjectsParams,
  context?: NewsObjectContext,
) {
  return this.api.get<ApiResponse<NewsObject[]>>(`/kiosk/feeds/${feedId}/newsobjects`, {
    params,
    headers: belgaContextHeaders(context),
  });
}

export function updateFeedCoverImage(this: RestApiService, feedId: Feed['id'], coverImage: Blob) {
  return this.api.put<FormData, AxiosResponse<CoverImage>>(
    `/feeds/${feedId}/cover`,
    getFormData({ coverImage }),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}
export function deleteFeedCoverImage(this: RestApiService, feedId: Feed['id']) {
  return this.api.delete<{ coverImage: string }>(`/feeds/${feedId}/cover`);
}
export function restoreFeedCoverImage(this: RestApiService, feedId: Feed['id']) {
  return this.api.put<CoverImage>(`/feeds/${feedId}/cover/reset`);
}
