import { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.neutral01[700],
    }),
    paper: ({ theme }) => ({
      padding: 0,
      margin: theme.spacing(0.5, 0, 0),
      borderRadius: 4,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    }),
    option: ({ theme }) => ({
      minHeight: 'auto',
      alignItems: 'center',
      padding: 0,
      paddingBlock: theme.spacing(0.5),
      paddingInline: theme.spacing(1),
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
      },

      borderBottom: `1px solid ${theme.palette.neutral01[600]}`,

      '&:hover': {
        backgroundColor: theme.palette.global01[700],
      },
    }),

    inputRoot: {
      width: 'auto',
    },
    groupLabel: ({ theme }) => ({
      marginTop: '6px',
      color: theme.palette.neutral01[300],
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '10px',
      borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
      marginBottom: 0,
      paddingBottom: '6px',
    }),
    listbox: () => ({
      padding: 0,
    }),
  },
};
